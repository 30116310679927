import * as React from 'react'
import BackgroundIndex from '../images/index-background.png';

import IndexLineBlack from '../images/svg/index-line-black.svg';


const PrivacyPage = ({ data }) => {
  console.log(data)
  return (
      <main>
        <div className="bg-gray-900 lg:overflow-hidden relative" style={{  
            backgroundImage: "url(" + BackgroundIndex + ")",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
            }}>
          <div className="mx-auto">
            <div className="">
              <div className="mx-auto md:items-center md:px-0">
                <div className="md:py-24 md:pl-20">
                  <h1 className="text-comorant lg:text-7xl text-6xl tracking-tight text-center text-white mb-6 pt-44 md:pt-0 md:px-0 px-14 uppercase">
                    <span className="block">PRIVACY</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* CONTENT PRIVACY SECTION */}
      
        <div className="bg-light py-24 sm:py-32 lg:py-40">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="text-left">
              <p className='text-base mb-5'>We respect the privacy of every person who registers with Club Mella or visits Club Mella’s website and/or uses the services made available by us (the “Club Mella Service”) and we are committed to ensuring a safe online experience.</p>
              <h3 className='text-5xl	text-comorant mb-5'>PURPOSE OF THIS NOTICE</h3>

              <p className='text-base mb-5'>This privacy notice explains our approach to any personal information that you might supply to us (or that might be collected from you) in connection with your use of the Club Mella Service and sets out your rights in respect of Club Mella’s processing of your personal information.</p>

              <p className='text-base mb-5'>This privacy notice will inform you how you can verify which of your personal information is collected by us through the Club Mella Service and how you can request that we delete, update or transfer it.</p>

              <p className='text-base mb-5'>This privacy notice is intended to assist you in making informed decisions when using the Club Mella Service. Please take a moment to read and understand it. Please note that it should be read in conjunction with our Terms of Use.</p>

              <p className='text-base mb-5'>Please also note that this privacy notice only applies to the use of personal information collected by us from your use of the Club Mella Service or during your communications with us. It does not apply to personal information collected during your communications with third parties (including any retail partners associated with Club Mella).</p>

              <h3 className='text-5xl	text-comorant mb-5'>WHO ARE WE AND WHAT DO WE DO?</h3>

              <p className='text-base mb-5'>The Club Mella Service is operated by Club Mella Limited (“Club Mella”) whose principal activities include operating a private members club and associated website. Please be aware that when you create a profile on Club Mella, your profile will be publicly available to Members.</p>

              <p className='text-base mb-5'>Club Mella is operated by ELF Media LLC (“us”, “we”, or “our”). We are registered in the state of Delaware, United States of America under company number 6896041 and our registered office is at 8 The Green, Suite R, Dover 19901.</p>

              <h3 className='text-5xl	text-comorant mb-5'>HOW TO CONTACT US?</h3>

              <p className='text-base mb-5'>If you have any questions about this privacy notice or want to exercise your rights, please contact us by:</p>

              <p className='text-base mb-5'>sending an email to info@clubmella.com</p>

              <h3 className='text-5xl	text-comorant mb-5'>WHAT PERSONAL INFORMATION DO WE COLLECT AND HOW DO WE DO IT?</h3>
              
              <p className='text-base mb-5'>Our primary goal in collecting personal information from you is to give you a customized experience while allowing us to provide services and features that most likely meet your needs. In order to do so personal information may be collected from you in the following ways:</p>

              <p className='text-base mb-5'>Information you voluntarily provide to us.</p>
              <p>We collect and maintain personal information that you voluntarily submit to us during your use of the Club Mella Service (e.g. when registering with the website, subscribing to Membership, during the course of your interactions and communications with any other members, through any email exchanges with us or as part of any customer support interactions).</p>

              <p className='text-base mb-5'>In particular, in order to sign up and create an account we require the following personal information from you:</p>

              <p>name;</p>
              <p>e-mail address;</p>
              <p>date of birth</p>
              <p>gender;</p>
              <p>Password</p>

              <p className='text-base mb-5'>In order to subscribe to paid Membership, our payment processor requires the following and other information from you (we do not collect or store payment information). You should check with your payment processor what information they collect and store:</p>

              <p>name;</p>
              <p>password;</p>
              <p>payment method;</p>
              <p>debit or credit card details or PayPal account information; and</p>
              <p>billing address.</p>

              <p className='text-base mb-5'>Through your use of the Club Mella Service, we may also ask you for the following additional personal information:</p>

              <p>your image/photo;</p>
              <p>description of yourself / your body;</p>
              <p>sexual preferences/interests;</p>
              <p>profession;</p>
              <p>city/country; and</p>
              <p>current relationship status.</p>

              <p className='text-base mb-5'>You have the flexibility to provide us with as little or as much of this information as possible. However, the more information you provide, the more you will get out of the Club Mella Service.</p>

              <p className='text-base mb-5'>If you contact us, we may collect additional information from you such as a telephone number.</p>

              <p className='text-lg font-bold mb-5'>Information we collect through your use of the Club Mella Service.</p>

              <p className='text-base mb-5'>We may collect through our use of cookies, pixels, beacons, log files, and other technologies personal information regarding your mobile device, computer hardware, and software used to access the Club Mella Service. This may include the following:</p>

              <p>IP address of device(s) used;</p>
              <p>browser type;</p>
              <p>operating system and device type;</p>
              <p>approximate location (e.g. Los Angeles);</p>
              <p>access times and dates;</p>
              <p>onsite navigation/clicks;</p>
              <p>profile views/likes;</p>
              <p>transaction history and</p>
              <p>referring website addresses.</p>

              <p className='text-base mb-5'>We may also collect personal information regarding your use of and activity on the Club Mella Service.</p>

              <p className='text-base mb-5'>This personal information allows us to deliver more helpful information, services, and tools. Please see our cookie policy for further information.</p>

              <p className='text-lg font-bold mb-5'>Information we collect from third parties.</p>

              <p className='text-base mb-5'>We may also combine personal information that we collect through your use of the Club Mella Service with personal information that: a) you have provided to third parties and in respect of which you have given the third-party permission to share with Club Mella; and/or b) we have obtained from a public</p>

              <h3 className='text-5xl	text-comorant mb-5'>HOW DO WE USE YOUR PERSONAL INFORMATION?</h3>

              <p className='text-base mb-5'>We collect and use your personal information to operate the Club Mella Service efficiently and to deliver you the very best service. In particular, we may use your personal information for the following purposes:</p>

              <p className='text-lg font-bold mb-5'>Provision of the Club Mella Service.</p>

              <p className='text-base mb-5'>We use your information to identify you and your account and to provide you with the features of the Service related to your membership and the information submitted and collected.</p>

              <p className='text-base mb-5'>It is necessary for us to use your personal information to provide the Club Mella Service to you and perform our obligations in accordance with the contract that we have with you. It is also in our legitimate interest to use your personal information in such a way as to ensure that we provide the very best service we can to you.</p>

              <p className='text-base mb-5'>We may use your information for customer profiling to ensure that we personalize the most suitable services for you.</p>

              <p className='text-base mb-5'>It is necessary for us to profile you as it is in our legitimate interests to provide the very best service we can to you.</p>

              <p className='text-base mb-5'>Service administration.</p>

              <p className='text-base mb-5'>We may use your personal information to:</p>

              <p className='text-base mb-5'>develop and improve the Club Mella Service;</p>
              <p className='text-base mb-5'>send you administrative e-mails about the Club Mella Service; and</p>
              <p className='text-base mb-5'>contact you to answer any queries you may have.</p>

              <p className='text-base mb-5'>It is in our legitimate interest to use your personal information in such a way as to ensure that we provide the very best service we can to you.</p>

              <p className='text-base mb-5'>Internal record keeping and legal compliance.</p>

              <p className='text-base mb-5'>We may use your personal information for internal record-keeping purposes and to check you comply with the Membership rules and Terms of Use.</p>

              <p className='text-base mb-5'>It is in our legitimate interest to ensure that we keep our records up-to-date in order to monitor who uses the Club Mella Service, to comply with our legal obligations, and to monitor your compliance with the Terms of Use.</p>

              <p className='text-base mb-5'>Marketing by us.</p>

              <p className='text-base mb-5'>We may send you information about our services or products that we think may be of interest to you.</p>

              <p className='text-base mb-5'>Your consent.</p>

              <p className='text-base mb-5'>It is in our legitimate interest to share your personal information in such a way to ensure that we provide the very best service we can to you. When we share your sensitive personal data, we shall be relying on your consent to do so.</p>

              <p className='text-base mb-5'>Sharing information with professional advisors & potential purchasers.</p>

              <p className='text-base mb-5'>We may share your personal information with professional advisers or regulatory authorities:</p>

              <p className='text-base mb-5'>(i) – to enable them to process your personal information on our behalf in a manner consistent with this privacy notice;</p>

              <p className='text-base mb-5'>(ii) – to comply with our legal obligations;</p>

              <p className='text-base mb-5'>(iii) – to enforce our legal rights; or</p>

              <p className='text-base mb-5'>(iv) – protect rights of third parties.</p>
             
              <p className='text-base mb-5'>Your personal information may also be shared with prospective buyers if we go through a business transition such as a merger, acquisition by another company, or sale of all or a portion of our assets.</p>

              <p className='text-base mb-5'>It is in our legitimate interest to share your personal information with such third parties to enable them to process your information on our behalf, to comply with and/or enforce our legal rights and obligations, to protect the rights of third parties and to facilitate business transitions. Where we share your sensitive personal data, we shall be relying on your consent to do so.</p>

              <p className='text-base mb-5'>Any other purposes for which we wish to use your personal information will be notified to you either by updating our privacy notice or otherwise from time to time including the legal basis of such use.</p>

              <h3 className='text-5xl	text-comorant mb-5'>HOW DO WE OBTAIN YOUR CONSENT?</h3>

              <p className='text-base mb-5'>Where the use of your personal information by us requires your consent, you can provide such consent:</p>

              <p className='text-base mb-5'>at the time we collect your personal information following the instructions provided, for example at the point of application for membership; or
                by informing Club Mella by e-mail, post or phone using the contact details set out in this privacy notice.
              </p>

              <h3 className='text-5xl	text-comorant mb-5'>USE OF COOKIES</h3>

              <p className='text-base mb-5'>The Club Mella Service may use certain cookies, pixels, beacons, log files and other technologies of which you should be aware. Please see our Cookie Policy to find out more about the cookies we use and how to manage and delete cookies.</p>

              <h3 className='text-5xl	text-comorant mb-5'>THIRD-PARTY LINKS AND SERVICES</h3>

              <p className='text-base mb-5'>The Club Mella Service may contain links to third-party websites and services. Please remember that when you use a link to go from Club Mella to another website or you request a service from a third party, this privacy notice no longer applies.</p>

              <p className='text-base mb-5'>Your browsing and interaction on any other website, or your dealings with any other third-party service provider, is subject to that website’s or third-party service provider’s own rules and policies.</p>

              <p className='text-base mb-5'>We do not monitor, control, or endorse the privacy practices of any third parties.</p>

              <p className='text-base mb-5'>We encourage you to become familiar with the privacy practices of every website you visit or third-party service provider that you deal with and to contact them if you have any questions about their respective privacy policies and practices.</p>

              <p className='text-base mb-5'>This privacy notice applies solely to personal information collected by us through the Club Mella Service and does not apply to these third-party websites and third-party service providers.</p>

              <h3 className='text-5xl	text-comorant mb-5'>HOW LONG DO WE KEEP YOUR PERSONAL INFORMATION FOR?</h3>

              <p className='text-base mb-5'>We do not keep your personal data for any specific period but will not keep it for longer than is necessary for our purposes.</p>

              <p className='text-base mb-5'>In considering how long to keep it, we will take into account its relevance to our business and our legal and regulatory obligations.</p>

              <h3 className='text-5xl	text-comorant mb-5'>CONFIDENTIALITY AND SECURITY OF YOUR PERSONAL INFORMATION</h3>

              <p className='text-base mb-5'>We are committed to keeping the personal information you provide us secure and we will take reasonable precautions to protect your personal information from loss, misuse, or alteration.</p>

              <p className='text-base mb-5'>We have implemented information security policies, rules, and technical measures to protect the personal information that we have under our control from:</p>

              <p>unauthorized access;</p>
              <p>improper use or disclosure;</p>
              <p>unauthorized modification;</p>
              <p>unlawful destruction or accidental loss.</p>
              <p>All our employees and data processors (i.e. those who process your personal information on our behalf, for the purposes listed above), who have access to, and are associated with the processing of personal information, are obliged to respect the confidentiality of the personal information of all users of the Club Mella Service.</p>
              
              <h3 className='text-5xl	text-comorant mb-5'>HOW TO ACCESS YOUR INFORMATION AND YOUR OTHER RIGHTS?</h3>

              <p className='text-base mb-5'>You have the following rights in relation to the personal information we hold about you:</p>

              <p className='text-base mb-5'>Your right of access.</p>

              <p className='text-base mb-5'>If you ask us, we’ll confirm whether we’re processing your personal information and, if so, provide you with a copy of that personal information (along with certain other details). If you require additional copies, we may need to charge a reasonable fee.</p>

              <p className='text-base mb-5'>Your right to rectification.</p>

              <p className='text-base mb-5'>If the personal information we hold about you is inaccurate or incomplete, you’re entitled to have it rectified. If we’ve shared your personal information with others, we’ll let them know about the rectification where possible. If you ask us, where possible and lawful to do so, we’ll also tell you who we’ve shared your personal information with so that you can contact them directly.</p>

              <p className='text-base mb-5'>Your right to erasure.</p>

              <p className='text-base mb-5'>You can ask us to delete or remove your personal information in some circumstances such as where we no longer need it or you withdraw your consent (where applicable). If we’ve shared your personal information with others, we’ll let them know about the erasure where possible. If you ask us, where possible and lawful to do so, we’ll also tell you who we’ve shared your personal information with so that you can contact them directly.</p>

              <p className='text-base mb-5'>Your right to restrict processing.</p>

              <p className='text-base mb-5'>You can ask us to ‘block’ or suppress the processing of your personal information in certain circumstances such as where you contest the accuracy of that personal information or object to us processing it. It won’t stop us from storing your personal information though. We’ll tell you before we lift any restrictions. If we’ve shared your personal information with others, we’ll let them know about the restriction where possible. If you ask us, where possible and lawful to do so, we’ll also tell you who we’ve shared your personal information with so that you can contact them directly.</p>

              <p className='text-base mb-5'>Your right to data portability.</p>

              <p className='text-base mb-5'>You have the right, in certain circumstances, to obtain personal information you’ve provided us with (in a structured, commonly used, and machine-readable format) and to reuse it elsewhere or ask us to transfer this to a third party of your choice.</p>

              <p className='text-base mb-5'>Your right to object.</p>

              <p className='text-base mb-5'>You can ask us to stop processing your personal information, and we will do so if we’re:</p>

              <p className='text-base mb-5'>– relying on our own or someone else’s legitimate interests to process your personal information except if we can demonstrate compelling legal grounds for the processing;</p>

              <p className='text-base mb-5'>– processing your personal information for direct marketing; or</p>

              <p className='text-base mb-5'>– processing your personal information for research unless such processing is necessary for the performance of a task carried out in the public interest.</p>

              <p className='text-base mb-5'>Your rights in relation to automated decision-making and profiling.</p>

              <p className='text-base mb-5'>You have the right not to be subject to a decision when it’s based on automatic processing, including profiling, and it produces a legal effect or similarly significantly affects you unless such profiling in necessary for entering into, or the performance of, a contract between you and Club Mella.</p>

              <p className='text-base mb-5'>Your right to withdraw consent.</p>

              <p className='text-base mb-5'>If we rely on your consent (or explicit consent) as our legal basis for processing your personal information, you have the right to withdraw that consent at any time.</p>

              <h3 className='text-5xl	text-comorant mb-5'>CHANGE TO THIS PRIVACY NOTICE</h3>

              <p className='text-base mb-5'>We may make changes to this privacy notice from time to time.</p>

              <p className='text-base mb-5'>To ensure that you are always aware of how we use your personal information we will update this privacy notice from time to time to reflect any changes to our use of your personal information. We may also make changes as required to comply with changes in applicable law or regulatory requirements. We may notify you by e-mail of any significant changes. However, we encourage you to review this privacy notice periodically to be informed of how Club Mella uses your personal information.</p>


            </div>
          </div>
        </div>

      </main>
  )
}



export default PrivacyPage